import React, { useState } from 'react';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

const PopoverView = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      {' '}
      <UncontrolledPopover trigger="hover" placement="top" target={`flag${props.flag}`}>
        <PopoverHeader className="f-20 p-medium" style={{background:'linear-gradient(to right,#140E38,#194089)', color:'white',textAlign:'center'}}>
        <i className="fas fa-info-circle"></i><br/>What you will learn?</PopoverHeader>
        <PopoverBody style={{textAlign:'center'}} className="font-popins-r f-14">{props.info} </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
}

export default PopoverView;