import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Api from "./components/APIs/Api";
import "./index.css";
import Login from "./components/Login";
import reportWebVitals from "./reportWebVitals";
import URL from "./config";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./components/Login-popup.css";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
// const token_new = urlParams.get('token');
const course_id = urlParams.get("course_id");
const Unstoppable_login = urlParams.get('Unstoppable-login');
// const course_id = "290fbc2d-557a-4abe-88ff-b184e408ef70";

function setCookie(name, value, daysToLive) {
  var cookie = name + "=" + encodeURIComponent(value);
  if (typeof daysToLive === "number") {
    cookie += "; domain=finstreet.in; max-age=" + daysToLive * 24 * 60 * 60;
    document.cookie = cookie;
  }
}

function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function delete_cookie(name) {
  document.cookie =
    name +
    "=;Domain=finstreet.in; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

ReactDOM.render(
  <div
    className="cookies-container text-center"
    style={{ background: "linear-gradient(to right,#140E38,#194089)" }}
  >
    {/* <div className="loader-box">
      <div
        style={{ color: "#00008B", width: "50px", height: "50px" }}
        className="spinner-border"
        role="status"
      ></div>
    </div> */}
    <div className="loader-container">
      <div className="kinetic"></div>
    </div>
  </div>,
  document.querySelector("#root") 
);

//production
var token = getCookie("token");
// console.log(token);
// var token =
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZW51bWJlciI6IjgyOTk3NTQyNTAiLCJpYXQiOjE2MjgyMzY0MTIsImV4cCI6MTYzMzQyMDQxMn0.4gbQvzUjqfFmSI_ZSAMrk2WywzbF0zp8iLxXvfpdgYk";
//testing copy token from the test site cookie after login
if (token && course_id) {
  fetchUser();
} else {
  delete_cookie("token");
  window.location.replace(URL + "/login-register");
}
// var signedIn = getCookie('isSignedIn')
// if (!signedIn){
//   setCookie('isSignedIn','true',30)
//   if(!token){
//     setCookie('token', token_new, 30);
//     fetchUser();
//     window.location.reload();
//   }
//   else if (token===token_new) {
//     fetchUser();
//   }else if(token !== token_new){
//     setCookie('token', token_new, 30);
//     fetchUser();
//     window.location.reload();
//   }else{
//     window.location.replace('http://test.finstreet.in/login');
//   }
// }
// else if (signedIn === 'true') {
//   if(!token){
//     setCookie('token', token_new, 30);
//     fetchUser();
//     window.location.reload();
//   }
//   else if (token === token_new) {
//     fetchUser();
//   }else if(token !== token_new){
//     setCookie('token', token_new, 30);
//     fetchUser();
//     window.location.reload();
//   }else{
//     window.location.replace('http://test.finstreet.in/login');
//   }
// }
// else if (signedIn === 'false'){
//   if (!token) {
//     document.cookie = 'isSignedIn=true';
//     setCookie('token',token_new,30);
//     fetchUser();
//     window.location.reload()
//   }
//   else if (token) {
//     if (token === token_new){
//       window.location.replace('http://test.finstreet.in/login');
//     }
//     else{
//       document.cookie = 'isSignedIn=true';
//       setCookie('token',token_new,30);
//       fetchUser();
//       window.location.reload()
//     }
//   }
// }
console.log(Unstoppable_login);
async function fetchCourse(number, user) {
  const response = await Api.get(
    `/single_purchased_course?course_id=${course_id}&phonenumber=${number}`
  );
  ReactDOM.render(
    <App user={user} course={response.data} />,
    document.querySelector("#root")
  );
}

async function fetchUser() {
  if(Unstoppable_login !== null){
    const response = await Api.post(`/unstoppableUserInfo?token=${token}`);
    if (response.data.status === "failure") {
      ReactDOM.render(<Login />, document.querySelector("#root"));
    } else {
      fetchCourse(response.data.User.mobile_no, response.data);
    }
  }
  if(Unstoppable_login == null){
    const response = await Api.get(`/userinfo?token=${token}`);
    if (response.data.status === "failure") {
      ReactDOM.render(<Login />, document.querySelector("#root"));
    } else {
      fetchCourse(response.data.User.mobile_no, response.data);
    }
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
